<template>
  <div class="table-article-list">
    <div class="table-article-list__thead">
      <div class="table-article-list__th">{{ $t("article.list.type") }}</div>
      <div class="table-article-list__th">{{ $t("article.list.title") }}</div>
      <div class="table-article-list__th">
        {{ $t("article.list.main_rubric") }}
        <span class="table-article-list__th-sub">{{ $t("article.list.site") }}</span>
      </div>
      <div class="table-article-list__th">{{ $t("article.list.author") }}</div>
      <div class="table-article-list__th">
        {{ $t("article.list.order_date") }}
        <span class="table-article-list__th-sub">{{ $t("article.list.last_modified") }}</span>
      </div>
      <div class="table-article-list__th">{{ $t("article.list.status") }}</div>
      <div class="table-article-list__th">{{ $t("actions") }}</div>
    </div>
    <div class="table-article-list__tbody">
      <TableTr
        v-for="(article, index) in articles"
        :key="`item-${index}`"
        :article="article"
        :is-allowed-to-edit="isAllowedToEdit"
      />
    </div>
    <div class="table-article-list__bottom-bar">
      <ArticleTotalCount :total-count="totalCountOfArticles" />
      <Pagination
        v-if="articles.length"
        :value="currentPage"
        @input="$emit('current-page-changed', $event)"
        :page-count="pageCount"
        :click-handler="clickHandler"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import ArticleTotalCount from '@/components/article/ArticleTotalCount'
import TableTr from '@/components/table/TableTr'

export default {
  name: 'TableArticleList',
  props: {
    articles: {
      type: Array,
      required: true
    },
    isAllowedToEdit: {
      type: Function,
      required: true
    },
    totalCountOfArticles: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    clickHandler: {
      type: Function,
      required: true
    }
  },
  components: {
    TableTr,
    Pagination,
    ArticleTotalCount
  }
}
</script>

<style lang="scss">
  .table-article-list {
    border: none;
    display: block;
    @include bp(0 12) {
      margin-top: rem(20px);
    }
    @include bp(12) {
      background: #fff;
      border-bottom-left-radius: rem(6px);
      border-bottom-right-radius: rem(6px);
      padding: rem(30px) rem(30px) 0 rem(30px);
    }
    &__thead {
      background: #F5F5F9;
      border-radius: rem(6px);
      padding: rem(16px) 0 rem(16px) rem(20px);
      @include bp(0 12) {
        display: none;
      }
    }
    &__thead,
    &__tr {
      @include bp(12) {
        display: grid;
        grid-gap: rem(20px);
        align-items: center;
        grid-template-columns: max-content auto rem(130px) rem(130px) rem(150px) rem(120px) max-content;
      }
      @include bp(14) {
        grid-template-columns: max-content auto rem(130px) rem(160px) rem(200px) rem(120px) rem(175px);
      }
    }
    &__th {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 600;
      color: #8A96AC;
      &:last-child {
        padding-right: rem(20px);
      }
    }
    &__tbody {
      @include bp(0 8) {
        display: grid;
        grid-gap: rem(20px);
      }
      @include bp(8 12) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: rem(20px);
      }
    }
    &__td {
      @include bp(0 12) {
        &:not(:last-child) {
          margin-bottom: rem(15px);
        }
      }
      &--btns {
        @include bp(0 12) {
          display: flex;
          gap: rem(6px);
          flex-wrap: wrap;
        }
        @include bp(12 14) {
          display: grid;
          grid-gap: 3px;
        }
        @include bp(14) {
          display: flex;
          gap: rem(6px);
        }
      }
      &--type {
        @include bp(0 12) {
          display: none;
        }
      }
    }
    &__td-desc {
      @include bp(0 12) {
        background: rgba(101, 153, 254, .2);
        color: #568df7;
        font-weight: 500;
        display: block;
        padding: rem(3px) rem(8px);
        border-radius: rem(3px);
        width: 100%;
        margin-bottom: rem(5px);
      }
      @include bp(12) {
        display: none;
      }
    }
    &__th-sub,
    &__td-sub {
      font-size: rem(13px);
      font-weight: 400;
      opacity: .9;
      display: block;
    }
    &__tr {
      position: relative;
      @include bp(0 12) {
        background: #fff;
        border-radius: rem(6px);
        padding: rem(15px);
        box-shadow: 0 0 12px 2px #dadada;
      }
      @include bp(12) {
        padding: rem(14px) 0 rem(14px) rem(16px);
        align-items: center;
      }
      &:not(:last-child) {
        @include bp(12) {
          border-bottom: 1px solid #D1DBE4;
        }
      }
      &:last-child {
        @include bp(12) {
          border-bottom: 1px solid transparent;
        }
      }
      &::before {
        position: absolute;
        left: rem(-30px);
        top: -1px;
        width: calc(100% + 60px);
        height: calc(100% + 2px);
        content: '';
        z-index: 1;
        background: rgba(101, 153, 254, .1);
        pointer-events: none;
        opacity: 0;
        //border-top: 1px solid #D1DBE4;
        //border-bottom: 1px solid #D1DBE4;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
    &__td-title,
    &__rubric,
    &__site,
    &__order-date {
      font-family: "Roboto", sans-serif;
      font-size: rem(15px);
      font-weight: 500;
      color: #465674;
    }
    &__icon-article {
      width: rem(30px);
      height: auto;
    }
    &__type {
      @include bp(12) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    &__rubric,
    &__order-date {
      font-weight: 600;
    }
    &__site,
    &__author,
    &__created-by {
      font-family: "Roboto", sans-serif;
      font-size: rem(15px);
      display: block;
      font-weight: 400;
      color: #8490A7;
    }
    &__bottom-bar {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
      justify-content: space-between;
      margin-top: rem(30px);
      padding-bottom: rem(30px);
    }
  }
</style>
